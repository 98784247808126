import { Layout } from "../components/layout";
import Image from "next/image";
import { SWRConfig } from "swr";
import React from "react";
import { fetcher } from "../utils/misc";
import { CATEGORIES } from "../utils/endpoints";

export default function Custom404({ fallback }) {
  return (
    <SWRConfig value={{ fallback }}>
      <Layout>
        <div className="section-container relative pb-40">
          <div className="relative py-10 px-28">
            <div className="absolute inset-x-0 top-0 h-1/2 bg-fel-purple" />
            <div className="aspect-w-1133 aspect-h-453 relative px-8">
              <Image
                src="/errorPage.png"
                alt="Foire en ligne"
                layout="fill"
                objectFit="contain"
              />
            </div>
          </div>
          <h1 className="section-title text-center text-5xl text-fel-yellow">
            Oups ! Page introuvable
          </h1>
        </div>
      </Layout>
    </SWRConfig>
  );
}

export async function getStaticProps() {
  return {
    props: {
      fallback: {
        [CATEGORIES]: await fetcher(CATEGORIES),
      },
    },
    revalidate: 600,
  };
}
